import React, {useState, useEffect} from 'react';
import { FireStoreService } from '../../services/firebase/firebaseService';
import Swal from 'sweetalert2';
import './AdminUsers.css';
import AddAdminDirectory from '../addAdminDirectory/AddAdminDirectory';
import { renderComponentInSwal } from '../../services/swal/renderMyComponent';

// eslint-disable-next-line import/no-anonymous-default-export
export default () => {
  const [users, setUsers] = useState([]);
  // const [directories, setDirectories] = useState({});

  function removeFirebaseUser(id){
    Swal.fire({
      title: 'Are you sure?',
      text: `Do you really want to delete the user?`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      cancelButtonText: 'NO, go back',
      confirmButtonText: 'YES'
    }).then((result) => {
      if (result.value) {
        if (id) {
          FireStoreService.deleteDoc('admin', id);
          Swal.fire({
            icon: 'success',
            title: 'The user has been deleted.',
          })
        }else{
          Swal.fire({
            icon: 'error',
            title: 'Error',
            text: `Please, try again.`,
          })
        }
      }
    })
  }

  useEffect(() => {
    let directories = {};
    async function getDirectories() {
      try {
        const directoriesFirestore = await FireStoreService.getCollection('directories');
        directoriesFirestore.map(directory => {
          directories = {
            ...directories,
            [directory.id]: directory.name
          }
          return directory;
        })
      } catch (error) {
        console.log(error);
      }
    }
    getDirectories();

    const addUser = (user) => {
      const userData = user.data();
      console.log(directories);
      setUsers((prev) => ([
        ...prev,
        <div className='users__user' key={user.id}>
          <span>{userData.email}</span>
          <span>{userData.accessLevel}</span>
          <span>{directories[userData.school]}</span>
          <span className='closeButton' onClick={() => removeFirebaseUser(user.id)}></span>
        </div>
      ]));
      return null;
    }
    
    const changeUser = (user) => {
      setUsers(prev => {
        console.log(user.key);
        prev.map(localUser => 
          localUser.key === user.key ? 
          <div className='users__user' key={user.id}>
            <span>{localUser.email}</span>
            <span>{localUser.accessLevel}</span>
            <span>{localUser.school}</span>
            <span className='closeButton' onClick={() => removeFirebaseUser(user.id)}></span>
          </div>
          : localUser
        )
      });
    };

    const removeUser = (user) => {
      // const localUsers = users.filter((localUser) => user.id !== localUser.key );

      setUsers(prev => prev.filter((localUser) => user.id !== localUser.key ));
    }

    FireStoreService.getRealTimeCollection('admins', addUser, changeUser, removeUser);
  }, []);

  function createUser() {
    renderComponentInSwal(<AddAdminDirectory />, 'Admin added', 'the new admin has been added');
  }

  return (
    <div>
      <div className='titleSection'>
        <h1>Directory admins</h1>
        <button className='buttonPrimary' onClick={createUser}>Create admin</button>
      </div>
      <div className='users'>
        {users}
      </div>
    </div>
  )
}