import React, {useState, useEffect} from 'react';
import { FireStoreService } from '../../services/firebase/firebaseService';
import { updateClientinSwal } from '../../services/swal/renderMyComponent';
import Swal from 'sweetalert2';
import './Advertisments.css';

export default () => {
  const [ads, setAds] = useState([]);

  function removeFirebaseDirectory(id){
    Swal.fire({
      title: 'Are u sure?',
      text: `Do you really want to delete the Advertisment?`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      cancelButtonText: 'NO, cancel',
      confirmButtonText: 'SI!'
    }).then((result) => {
      if (result.value) {
        if (id) {
          FireStoreService.deleteDoc('advertisment', id);
          Swal.fire({
            icon: 'success',
            title: 'The Advertisment has been deleted.',
          })
        }else{
          Swal.fire({
            icon: 'error',
            title: 'Error',
            text: `There were an error deleting the Advertisment.`,
          })
        }
      }
    })
  }

  function acceptAdvertisment(id) {
    updateClientinSwal('Advertisment', id, 'ads', {addAproved: true});
  }

  useEffect(() => {
    const addAdvertisment = (user) => {
      const userData = user.data();
      console.log(userData);
      setAds((prev) => ([
        ...prev,
        <div className='Ads__ad' key={user.id}>
          <div className='add__data'>
            <span>{userData.firstname} {userData.lastname}</span>
            <span>{userData.email}</span>
            <span>{userData.businessName}</span>
            <span>{userData.businessLicense}</span>
            <span>{userData.phoneNumber}</span>
            <span>{userData.MontlyBilling}</span>
            <button className='buttonPrimary' onClick={() => acceptAdvertisment(user.id)} >Accept Advertisment</button>
          </div>
          <div className='add__Image'>
            <img src={userData.adPicture} alt='client advertise'/>
            <span className='closeButton' onClick={() => removeFirebaseDirectory(user.id)}></span>
          </div>
        </div>
      ]));
      return null;
    }
    
    const changeAdvertisment = () => {};

    const removeAdvertisment = (user) => {
      // const localUsers = users.filter((localUser) => user.id !== localUser.key );

      setAds(prev => prev.filter((localUser) => user.id !== localUser.key ));
    }

    FireStoreService.getRealTimeCollection('ads', addAdvertisment, changeAdvertisment, removeAdvertisment);
  }, []);

  return (
    <div>
      <div className='titleSection'>
        <h1>Advertisments</h1>
      </div>
      <div className='users'>
        {ads}
      </div>
    </div>
  )
}