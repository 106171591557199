export default class UserData {
  title;
  firstname;
  lastname;
  city;
  mailingAddress;
  state;
  phoneNumber;
  ssn;

  constructor(data){
    this.title = data.title || '';
    this.firstname = data.firstname || 'FirstName';
    this.lastname = data.lastname || 'LastName';
    this.mailingAddress = data.mailingAddress || 'Mailing Address';
    this.city = data.city || 'City';
    this.state = data.state || 'State';
    this.phoneNumber = data.phoneNumber || '+1 555 555 5555';
    this.zip = data.zip || '0000';
    this.birthday = data.birthday || '';
    this.ssn = data.SSN || '000 000 0000';
  }

  getFormData(verifyPhoneNumber){
    const userDataForm = [
      {
        id: 'sizedTitle',
        type: 'sizedBoxWithTitle',
        info: this.title,
      },
      {
        id: 'firstname',
        type: 'string',
        info: 'FirstName',
        value: this.firstname,
      },
      {
        id: 'lastname',
        type: 'string',
        info: 'LastName',
        value: this.lastname,
      },
      {
        id: 'mailingAddress',
        type: 'string',
        info: 'Mailing Address',
        value: this.mailingAddress,
      },
      {
        id: 'city',
        type: 'string',
        info: 'City',
        value: this.city,
      },
      {
        id: 'state',
        type: 'string',
        info: 'State',
        value: this.state,
      },
      {
        id: 'phoneNumber',
        type: 'phoneNumber',
        info: 'Mobile Phone',
        className: 'dynamic_form__input__withButton',
        value: this.phoneNumber,
        onClickEvent: verifyPhoneNumber,
        buttonText: 'Verify Phone Number'
      },
      {
        id: 'zipCode',
        type: 'string',
        info: 'ZIP/Postal Code',
        value: this.zip,
      },
      {
        id: 'birthday',
        type: 'date',
        info: 'Birthday',
        value: this.birthday,
        toolTip: 'So we can send you a gift'
      },
      {
        id: 'SSN',
        type: 'string',
        info: 'Social Security Number',
        value: this.ssn,
        toolTip: 'So you can send IRS 1099 to your tenant or landlord'
      },
    ]

    return userDataForm;
  }

  getRegisterValidation(values){
    let errors = {};
    if (values) {
      if (!values.firstname) {
        errors.firstname = 'Firstname is required!';
      } else if (values.firstname.length <= 1) {
        errors.firstname = 'Firstname has to be 1 character at less!';
      }
      if (!values.lastname) {
        errors.lastname = 'Lastname is required!';
      } else if (values.lastname.length <= 1) {
        errors.lastname = 'Lastname has to be 1 character at less!';
      }
    
      if (!values.phoneNumber) {
        errors.phoneNumber = 'Number is required!';
      }  
    }
    return errors
  }

  getEditValidation(values){
    let errors = {};
    if (values) {
      if (values.firstname.length <= 1) {
        errors.firstname = 'Firstname has to be 1 character at less!';
      }
      if (values.lastname.length <= 1) {
        errors.lastname = 'Lastname has to be 1 character at less!';
      }
    
      if (!values.phoneNumber) {
        errors.phoneNumber = 'Number is required!';
      }
    }

    return errors
  }
}