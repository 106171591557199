import React from 'react';

// import Swal from 'sweetalert2';
import Swal from 'sweetalert2';
import { FireStoreService, AuthService } from '../../services/firebase/firebaseService';
import { readXLSXFromFile, writeXLSXfromJSON } from '../../services/XLSX/xlsxService';

function ImportExport({ schoolID }){
  const handleExport = async () => {
    const clientsArray = await FireStoreService.getCollection('guardians');

    const dateOfCreation= new Date();

    writeXLSXfromJSON(clientsArray, 'guardians', `Guardians ${dateOfCreation.getFullYear()}-${dateOfCreation.getMonth() + 1}-${dateOfCreation.getDate()}`);

    Swal.fire({
      icon: "success",
      title: 'File created'
    });
  }

  const handleImport = async(event) => {
    const file = event.target.files[0];

    async function createGuardiantAuthAccounts(accountData){
      const currentUserIDToken = await AuthService.getUser().getIdToken(true);
      
      const myHeaders = new Headers();
      myHeaders.append("Authorization", `Bearer ${currentUserIDToken}`);
      myHeaders.append("Content-Type", "application/json");
      
      console.log(schoolID);
      const body = {
        usersAccount: accountData.principalGuardiansData,
        students: accountData.studentsData,
        anotherGuardians: accountData.guardians2Data,
        schoolID
      }
      console.log(body);
      
      const req = {
        method: 'POST',
        headers: myHeaders,
        body: JSON.stringify(body),
        redirect: 'follow'
      }

      const path = 'createUsersFromJSON';

      const respPromise = await fetch(`https://us-central1-sage-120a5.cloudfunctions.net/server/${path}`, req);
      const resp = respPromise;

      console.log('Usuarios: ', resp);
    }

    const readyFunction = async (JSON) => {
      let principalGuardiansData = [];
      let studentsData = [];
      let guardians2Data = [];

      JSON.map((data) => {
        principalGuardiansData.push({
          name: data['Parent or Guardian Name #1'],
          email: data['Parent or Guardian Name #1 Email'],
          address: data['Parent or Guardian Name #1 Address'],
          phoneNumber: data['Parent or Guardian Name #1 Phone Number'],
          accountAproved: true,
        })

        studentsData.push({
          grade: data['Student Grade'],
          name: data['Student Name'],
          devices: data['What gaming platforms do you have?'],
          streamServices: data['What streaming services if any do you subscribe to?'],
          videoGames: data['My student plays the following video games.'],
        })

        guardians2Data.push({
          name: data['Parent or Guardian #2 Name'],
          email: data['Parent or Guardian #2 Email'],
          address: data['Parent or Guardian #2 Address'],
          phoneNumber: data['Parent or Guardian #2 Phone Number'],
        })
        // principalGuardiansData[index],
        // return createFamilyAccount(guardians2Data[index], studentsData[index], schoolID);
      });

      await createGuardiantAuthAccounts({principalGuardiansData, studentsData, guardians2Data});
      
      Swal.fire({
        icon: "success",
        title: 'File imported'
      });
    }

    readXLSXFromFile(file, readyFunction);
  }

  return (
    <div >
      <div className='section__title'>
        <h1>Import/Export data</h1>
      </div>

      <button className='btn' onClick={handleExport} >Export data</button>
      <input type='file' className='btn' onChange={handleImport} />
    </div>
  )
}

export default ImportExport