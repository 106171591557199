import React, { useEffect, useState } from 'react';
import { Switch, Route, Redirect, matchPath, useLocation, withRouter, useHistory } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux';
import { AuthService, FireStoreService } from './services/firebase/firebaseService';
import { changeUser } from "./services/redux/actions";

// Routes for the Router
import Header from './components/header/Header';
import Search from './screens/search/Search';
import Loading from './components/loading/Loading'
import NotFound from './screens/not_found/NotFound';
import Sign from './screens/sign/Sign';
import RegisterUser from './screens/register_user/RegisterUser';
import EditUser from './screens/edit_user/EditUser';
import Swal from 'sweetalert2';
import Advertise from './screens/advertise/Advertise';
import Family from './screens/family/Family';
import System from './screens/system/System';
import DirectoryAdmin from './screens/directoryAdmin/DirectoryAdmin';
import Forgot from './screens/forgot/Forgot';
import Landing from './screens/landing/Landing';

function App() {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const [auth] = useState(AuthService);
  const [schoolName, setSchoolName] = useState()
  const user = useSelector(state => state.user);
  let location = useLocation();
  let history = useHistory();

  useEffect(() => {
    if (user.uid !== 'none' && user.school) {
      FireStoreService.getDoc('directories', user.school)
        .then(school => {
          if (school) {
            const color = school.color || '#0f3057';
            document.documentElement.style.setProperty('--clr-primary', color);
          }
        })
        .catch(err => { throw err; });
    }
  }, [user]);

  useEffect(() => {
    async function checkUser(uid, collection) {
      try {
        const firebaseUser = await FireStoreService.getDoc(collection, uid);
        return firebaseUser;
      } catch (err) {
        console.log(err);
        throw err;
      }
    };

    async function getSchool(schoolID) {
      const school = await FireStoreService.getDoc('directories', schoolID);
      if (school) {
        setSchoolName(() => school.url);
      }
    }

    async function setUser({ uid, email }) {
      setLoading(true);
      try {
        let reduxAction;
        let school;
        if (uid !== "none" && email) {
          const metadataRef = await checkUser(uid, 'admins') || { accessLevel: '0', school: 'School not valid' };
            // await checkUserByEmail(email);

          console.log(metadataRef);

          if (metadataRef.accessLevel > 0) {
            const user = await AuthService.getUser();
            let accessLevel;
            let idTokenResult;

            //Verificar si current user llegó (no se puede usar la respuesta de la persistencia para obtener el token)
            if (user) {
              idTokenResult = await user.getIdTokenResult();
              if (
                idTokenResult.claims.accessLevel === metadataRef.accessLevel &&
                idTokenResult.claims.school === metadataRef.school
              ) {
                accessLevel = idTokenResult.claims.accessLevel;
                school = idTokenResult.claims.school;
                if (school) {
                  getSchool(school);
                }
                reduxAction = changeUser({ uid, email, accessLevel, school });
              } else if (idTokenResult.claims.accessLevel && idTokenResult.claims.school) {
                Swal.fire({
                  position: 'center',
                  icon: 'warning',
                  title: 'There were a problem with your credentials',
                  text: 'please log in again'
                })
                AuthService.signOut();
              }
            }
          } else{
            const UserAccount = await checkUser(uid, 'guardians');
            //Verificamos si el usuario existe
            if (UserAccount) {
              //Verificamos si el usuario tiene su cuenta aprobada
              if (!UserAccount.accountAproved) {
                Swal.fire({
                  icon: 'error',
                  title: 'Access Denied, Maybe your account has not been accepted',
                  showConfirmButton: true,
                });
                AuthService.signOut();
              }
              if (UserAccount.school) {
                getSchool(UserAccount.school);
              }
              reduxAction = changeUser({ uid, email, school: UserAccount.school });
            } else {
              Swal.fire({
                icon: 'error',
                title: 'Your register is not completed',
                showConfirmButton: true,
              })
              history.push('/register');
            }
          }
          dispatch(reduxAction);
          setLoading(false);          
        } else {
          reduxAction = changeUser({ uid, email });
          console.log(uid, email, reduxAction);
          dispatch(reduxAction);
          setLoading(false);
        }
      } catch (err) {
        console.log(err);
        // throw err;
      }
    }

    auth.authPersistence(setUser);
  }, [auth, dispatch]);

  // useEffect(() => {
  //   async function getSchools(){
  //     return (await FireStoreService.getCollection('directories'));

  //   }
  // }, [])

  return (
    <>
      {loading
        ?
        <Loading />
        :
        user.uid !== 'none' && schoolName ?
          <>
            <Header />
            <Switch>
              <Route path="/sign_up" exact>
                <Redirect to={`/${schoolName}/`} />
              </Route>
              <Route path="/login" exact>
                <Redirect to={`/${schoolName}/`} />
              </Route>
              <Route path="/search/" exact>
                <Redirect to={`/${schoolName}/search`} />
              </Route>
              <Route path="/:school/search" exact>
                <Search />
              </Route>
              <Route path="/family" exact>
                <Redirect to={`/${schoolName}/family`} />
              </Route>
              <Route path="/:school/family" exact>
                <Family />
              </Route>
              <Route path="/system" exact>
                {user.accessLevel > 4 ? <System /> : <Redirect to='/family' />}
              </Route>
              <Route path="/directory" exact>
                <Redirect to={`/${schoolName}/directory`} />
              </Route>
              <Route path="/:school/directory" exact>
                {user.accessLevel > 4 ? <DirectoryAdmin /> : <Redirect to='/family' />}
              </Route>
              <Route path="/:school/register" exact>
                {user.uid !== 'none' ? <RegisterUser /> : <Redirect to='/login' />}
              </Route>
              <Route path="/:school/edit_user" exact>
                {user.uid !== 'none' ? <EditUser /> : <Redirect to='/login' />}
              </Route>
              <Route path="/:school/" exact>
                <Search />
              </Route>
              <Route path="/" exact>
                <Redirect to={`/${schoolName}/`} />
              </Route>
              <Route>
                <NotFound />
              </Route>
            </Switch>
          </>
          :
          <Switch>
            <Route path="/:school/sign_up" exact component={props => MiddleWareComponent({element: <Sign isSignUp={true}/>, ...props})}></Route>
            <Route path="/:school/login" exact component={props => MiddleWareComponent({element: <Sign/>, ...props})}></Route>
            <Route path="/forgot" exact>
              <Forgot />
            </Route>
            <Route path="/:school/forgot" exact>
              <Forgot />
            </Route>
            <Route path="/advertise" exact>
              <Advertise />
            </Route>
            <Route path="/" exact>
              <Landing />
            </Route>
            <Route path="/:school/" exact strict >
              <Redirect to={`${location.pathname}login`} />
            </Route>
            <Route path="/:school" exact strict>
              <Redirect to={`${location.pathname}/login`} />
            </Route>
            <Route>
              <NotFound />
            </Route>
          </Switch>
      }
    </>
  );
}

const MiddleWareComponent = ({element, ...props}) => {
  const [isValidSchool, setIsValidSchool] = useState(false);
  const [loading, setLoading] = useState(true);
  async function checkSchoolPath(schoolURL) {
    try {
      const schoolResult = await FireStoreService.getQueryAsArray('directories', ['url', '==', schoolURL]);
      return schoolResult[0] ? true : false 
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    setLoading(true);
    checkSchoolPath(props.match.params.school).then(schoolMatched => {
      setIsValidSchool(schoolMatched);
      setLoading(false);
    });                
  }, [props.match.params.school])

  return (
    <>
      {
        loading
          ?
            <Loading />
          :
            isValidSchool ?
              React.cloneElement(element, {...props})
            :
              <div>
                NOT FOUND
              </div>
      }
    </>
  )
}

export default App;
