import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import { createBrowserHistory as BrowserHistory } from 'history'; //react-router Dependency

import { createStore } from 'redux'
import { Provider } from 'react-redux'
import reducer from './services/redux/reducer'

import App from './App';
import './styles.css'
import * as serviceWorker from './serviceWorker';

const initialState = {
  user: {
    uid: '',
    email: '',
    accessLevel: '',
    school: ''
  }
}

const store = createStore(reducer, initialState);

ReactDOM.render(
  <React.StrictMode>
    <Provider store = {store}>
      <Router history={ BrowserHistory }>
        <App />
      </Router>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);
// For production add
// "homepage": "http://diegosalazar27.github.io/garagexy/",

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register();
