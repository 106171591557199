import React from 'react';
import { useSelector } from 'react-redux';
import EditChild from '../components/addChild/EditChild';
import EditGuardian from '../components/addGuardian/EditGuardian';
import { renderComponentInSwal, updateClientinSwal, deleteComponentInSwal } from '../services/swal/renderMyComponent';

function deleteDocumentWithCloseButton(collection, document, message) {
  return (
    <span
      onClick={() => deleteComponentInSwal(message, document, collection)}
      className="closeButton"
    >
    </span>
  );
}

export const columnsGuardians = [
  {
    name: 'Name',
    selector: 'name',
    sortable: true
  },
  {
    name: 'Email',
    selector: 'email',
    sortable: true
  },
  {
    name: 'Address',
    selector: 'address',
    sortable: true
  },
  {
    name: 'Phone Number',
    selector: 'phoneNumber',
    sortable: true
  },
  {
    name: 'Actions',
    cell: (row) => <ActionsForGuardians data={row} />
  }
];

export const columnsChildren = [
  {
    name: 'Name',
    selector: 'name',
    sortable: true
  },
  {
    name: 'Grade',
    selector: 'grade',
    sortable: true
  },
  {
    name: 'Devices',
    selector: 'devices',
    sortable: true
  },
  {
    name: 'Videogames',
    selector: 'videoGames',
    sortable: true
  },
  {
    name: 'Favorite Cause',
    selector: 'favoriteCause',
    sortable: true
  },
  {
    name: 'Actions',
    cell: (row) => <ActionsForChildren data={row} />
  }
];

export const columnsGuardiansForSystem = [
  {
    name: 'Name',
    selector: 'name',
    sortable: true
  },
  {
    name: 'Email',
    selector: 'email',
    sortable: true
  },
  {
    name: 'Address',
    selector: 'address',
    sortable: true
  },
  {
    name: 'Phone Number',
    selector: 'phoneNumber',
    sortable: true
  },
  {
    name: 'Account Aproved',
    selector: 'phoneNumber',
    sortable: true
  },
  {
    name: 'Acciones',
    cell: row => <ActionsGuardiansForSystem id={row.id} />
  }
];

const ActionsGuardiansForSystem = ({id}) => {
  return (
    <span onClick={() => updateClientinSwal('Apove the user', id, 'guardians', {accountAproved: true})} className='buttonPrimary'>Aprove guardian</span>
  );
}

const ActionsForChildren = ({data}) => {
  const school = useSelector(state => state.user.school);
  return (
    <>
      <span onClick={() => renderComponentInSwal(<EditChild school={school} initialData={ data } />, 'children', 'children')} className='material-icons'>edit</span>
      {deleteDocumentWithCloseButton('children', data.id, data.name)}
    </>
  );
}

const ActionsForGuardians = ({data}) => {
  const school = useSelector(state => state.user.school);
  return (
    <>
      <span onClick={() => renderComponentInSwal(<EditGuardian school={school} initialData={ data } />, 'guardians', 'guardian')} className='material-icons'>edit</span>
      {deleteDocumentWithCloseButton('guardians', data.id, data.name)}
    </>
  );
}