import React from 'react';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { FireStoreService } from '../firebase/firebaseService';

export const renderComponentInSwal = (element, message1, message2) => {
  const MySwal = withReactContent(Swal);

  MySwal.fire({
    html: 
      React.cloneElement(element, {sweetAlert: MySwal})
    ,
    customClass: {
      popup: 'Swal__popup',
      closeButton: 'Swal__closeButton',
      content: 'Swal__Content',
    },
    showConfirmButton: false,
    showCloseButton: true,
    allowOutsideClick: true,
  }).then((result) => {
    if (result.isConfirmed) {
      Swal.fire(
        message1 ? message1 : 'Operación exitosa',
        message2 ? message2 : 'Operación exitosa',
        'success'
      );
    }
  }).catch(err => {
    Swal.fire(
      'Ha ocurrido un error',
      'La operación no se pudo completar',
      'error'
    );
    throw err;
  });
}

export const updateClientinSwal = (targetInfo, targetDB, collection, data) => {
  if (targetDB && collection)
  Swal.fire({
    title: 'Are you sure?',
    text: `Do you want to update the ${targetInfo ? targetInfo : 'elemento'}?`,
    icon: 'warning',
    showCancelButton: true,
    confirmButtonColor: '#3085d6',
    cancelButtonColor: '#d33',
    cancelButtonText: 'NO, cancelar',
    confirmButtonText: 'SI!'
  }).then((result) => {
    if (result.value) {
      if (targetDB) {
        FireStoreService.updateDoc(data, collection, targetDB);
        Swal.fire({
          icon: 'success',
          title: `The ${targetInfo ? targetInfo : 'element'} Has been updated.`,
        })
      }else{
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: `Error updating ${targetInfo ? targetInfo : 'elemento'}.`,
        })
      }
    }
  });
}

export const deleteComponentInSwal = (targetInfo, targetDB, collection) => {
  if (targetDB && collection)
  Swal.fire({
    title: 'Are you sure?',
    text: `Do you want to delete ${targetInfo ? targetInfo : 'element'}?`,
    icon: 'warning',
    showCancelButton: true,
    confirmButtonColor: '#3085d6',
    cancelButtonColor: '#d33',
    cancelButtonText: 'NO',
    confirmButtonText: 'YES'
  }).then((result) => {
    if (result.value) {
      if (targetDB) {
        FireStoreService.deleteDoc(collection, targetDB);
        Swal.fire({
          icon: 'success',
          title: `The ${targetInfo ? targetInfo : 'element'} has been eliminated.`,
        })
      }else{
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: `Error deleting ${targetInfo ? targetInfo : 'element'}.`,
        })
      }
    }
  });
}