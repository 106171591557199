import React, { useState } from 'react';
import { FireStoreService } from '../../services/firebase/firebaseService';
import './Search.css';

import {
  searchOptionsGuardians, searchValuesGuardians,
  searchOptionsChildren, searchValuesChildren
} from '../../models/constants';

import {
  columnsForGuardians, columnsForChildren,
  ExpandableForGuardianComponent,
  ExpandableForChildComponent
} from '../../models/searchResultsColumns';

import algoliasearch from 'algoliasearch/lite';
import {
  InstantSearch,
  SearchBox,
  Configure,
  connectHits,
  connectStateResults,
} from 'react-instantsearch-dom';
import { useSelector } from 'react-redux';
import { TableForAlgoliaResults } from '../../components/algoliaSearch/tablesForHits';

const client = algoliasearch('ALWBOEPNFR', 'da5e26b3d95f8d1700fc67d7f63f7051');

const SEARCHING_FILTERS = {
  'guardians': searchValuesGuardians,
  'children': searchValuesChildren
};

const CustomHits = connectHits(TableForAlgoliaResults);

export default () => {
  const [primarySearch, setPrimarySearch] = useState(null);
  const [optionsForSearching, setOptionsForSearching] = useState(null);
  const [expandableResults, setExpandableResults] = useState(false);
  const [ExpandableComponent, setExpandableComponent] = useState(<></>);
  const school = useSelector(state => state.user.school);
  const [hitVisibility, setHitVisibility] = useState(true);

  const [columnsResults, setColumnsResults] = useState([]);


  const [dataResults, setDataResults] = useState([]);

  const Results = connectStateResults(({ searchState }) =>
    searchState && searchState.query ? (
      <CustomHits
        ExpandableComponent={ExpandableComponent}
        columnsResults={columnsResults}
        collection={primarySearch}
      />
    ) : (
        <></>
      )
  );
  
  async function handleSearch(values) {
    try {
      if (values.valueForSearch !== '') {
        console.log('Before Searching', primarySearch, values.valueForSearch)
        const dataFilter = SEARCHING_FILTERS[primarySearch][values.valueForSearch] || SEARCHING_FILTERS[primarySearch].searchByValue;

        console.log(dataFilter, SEARCHING_FILTERS[primarySearch]);
        let queryFilters = [];

        switch (primarySearch) {
          case 'guardians': {
            queryFilters = [...queryFilters, ['accountAproved', '==', true]];
            setExpandableResults(() => true);
            setExpandableComponent(() => <ExpandableForGuardianComponent />);
            break;
          }

          case 'children': {
            console.log('CHIDREN SELECTED');
            // queryFilters = [...queryFilters, ['accountAproved', '==', true]];
            setExpandableResults(() => true);
            setExpandableComponent(() => <ExpandableForChildComponent />);
            break;
          }

          default:
            break;
        }

        dataFilter.filters.forEach(filter => {
          if (filter.value === 'givedByUser') {
            queryFilters = [...queryFilters, [filter.field, filter.condition, values.valueForSearch]];
          } else {
            queryFilters = [...queryFilters, [filter.field, filter.condition, filter.value]];
          }
        });
        console.log('Just before searching', queryFilters);
        const queryResults = await FireStoreService.getMultiFilterQueryDataAsArray(dataFilter.collection, queryFilters);
        console.log(queryResults);
        setDataResults(() => queryResults);
      }
    } catch (err) {
      console.log(err);
      throw err;
    }
  }

  function handleSelectChange(event) {
    const value = event.target.value;
    console.log('First select value', value);

    let data = [
      {
        id: 'valueForSearch',
        type: 'string',
        // options: [],
        value: '',
        hint: 'Full Name',
        info: 'Search '
      }
    ];

    switch (value) {
      case 'guardians': {
        setColumnsResults(() => columnsForGuardians);
        data = [
          {
            id: 'valueForSearch',
            type: 'string',
            // options: [],
            value: '',
            hint: 'Full Name',
            info: 'Search '
          }
        ];
        setExpandableComponent(() => <ExpandableForGuardianComponent expandable={true} />);
        data[0].options = searchOptionsGuardians;
        break;
      }

      case 'children': {
        setColumnsResults(() => columnsForChildren);
        data = [
          {
            id: 'valueForSearch',
            type: 'listValues',
            options: [],
            value: '',
            hint: 'Name',
            info: 'Search'
          }
        ];
        setExpandableComponent(() => <ExpandableForChildComponent expandable={true} />);
        data[0].options = searchOptionsChildren;
        break;
      }

      default: {
        data = null;
        break;
      }
    }

    setDataResults(() => []);

    setPrimarySearch(() => value);

    setOptionsForSearching(() => data);
  }

  function validation(values) {
    const errors = {};

    if (!values.valueForSearch) {
      errors.valueForSearch = 'Se requiere un filtro para la búsqueda.';
    }

    return errors;
  }

  function HitComponent({ hit }) {
    return (
      <span onClick={() => {
        setHitVisibility(false);
        handleSearch({ valueForSearch: hit.name });
      }}>{hit.name}</span>
    );
  }

  return (
    <section id='searchSection'>
      <div className='titleSection'>
        <h1>Search section</h1>
        <div className="checkBoxFields">
          <span>Search by:</span>
          <label
            className='CheckBoxContainer'
          >
            <input
              name="searchFields"
              type="radio"
              value='guardians'
              onChange={handleSelectChange}
            />
            <span className="checkmark">Guardians</span>
          </label>
          <label
            className='CheckBoxContainer'
          >
            <input
              name="searchFields"
              type="radio"
              value='children'
              onChange={handleSelectChange}
            />
            <span className="checkmark">Children</span>
          </label>
        </div>
      </div>
      { primarySearch &&
        <div className="algolia__search__container">
          <InstantSearch searchClient={client} indexName={primarySearch} on>
            <Configure
              filters={`school:${school}`}
              hitsPerPage={10}
            // enablePersonalization={true}
            />
            <SearchBox
              // onSubmit={(event) => {
              //   event.preventDefault();
              //   setHitVisibility(true)
              // }}
              showLoadingIndicator={true}
              searchAsYouType={false}
            />
            {
              hitVisibility &&
              <Results />
            }
          </InstantSearch>
        </div>
      }
      {/* {
        optionsForSearching &&
        <DynamicForm
          dataForm={[...optionsForSearching]}
          onSubmit={handleSearch}
          messageForButton='Search'
          validation={validation}
        />
      } */}
    </section>
  );
};