import React, { useEffect, useState, useCallback } from 'react';
import DynamicForm from '../../components/dynamic_form/dynamic_form';
import { AuthService, StorageService, FireStoreService } from '../../services/firebase/firebaseService';
import './RegisterUser.css';
// import { useHistory, Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux'
import Swal from 'sweetalert2';
import UserData from '../../models/userData';
import BankData from '../../models/bankData';
import { Redirect } from 'react-router-dom';

function EditUser() {
  const AuthInstance = AuthService;
  const db = FireStoreService;
  const storage = StorageService;
  const captchaRef = React.useRef(null);
  const [loading, setLoading] = useState(true);
  // const [redirect, setRedirect] = useState(false);
  const [userDataCredentials, setUserDataCredentials] = useState([]);
  const [userDataForm, setUserDataForm] = useState([]);
  const [userDataValidation, setUserDataValidation] = useState();
  const [bankDataForm, setBankDataForm] = useState([]);
  const [bankValidation, setBankValidation] = useState();
  const [bankRoutingInformation, setBankRoutingInformation] = useState();
  const [bankName, setBankName] = useState('')
  const [bankRoutingNumber, setBankRoutingNumber] = useState(<div className="ublcrnpoweredby">powered by <a href="//www.usbanklocations.com/check-routing-number.php">usbanklocations.com</a></div>);
  const user = useSelector(state => state.user);
  const [redirect, setRedirect] = useState();

  // const handleOnIdle = event => {
  //   console.log('last active', getLastActiveTime());
  //   Swal.fire(
  //     'Timeout',
  //     'Due to inactivity you were redirected',
  //     'warning'
  //   )
  //   setRedirect('./');
  // }
 
  // const handleOnActive = event => {
  //   // console.log('user is active', event);
  //   // console.log('time remaining', getRemainingTime());
  // }
 
  // const handleOnAction = (e) => {
  //   // console.log('user did something', e);
  // }

  // const { getRemainingTime, getLastActiveTime } = useIdleTimer({
  //   timeout: 1000 * 60 * 5,
  //   onIdle: handleOnIdle,
  //   onActive: handleOnActive,
  //   onAction: handleOnAction,
  //   debounce: 500,
  // })

  const documentsDataForm = [
    {
      id: 'sizedDocumentsTitle',
      type: 'sizedBoxWithTitle',
      info: 'Edit Documents',
    },
    {
      id: 'ownersInsurancePolicy',
      type: 'file',
      info: 'Owners Insurance Policy',
      value: '',
      fileType: '.pdf'
    },
    {
      id: 'govermentIssuedID',
      type: 'file',
      info: 'Goverment Issued ID',
      value: '',
      fileType: '.pdf',
    }
  ]

  function credentialValidation(values) {
    let errors = {};
   
    if (values.email !== '' && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
      errors.email = 'Invalid Email!';
    }

    if (!values.currentPassword) {
      errors.currentPassword = 'Current Password is required to change email or password!';
    }

    if (values.newPassword.length <= 4) {
      errors.newPassword = 'Password has to be 4 character at less!';
    }
    return errors;
  }


  function documentsValidation(values) {
    let errors = {}
    return errors
  }

  const verifyPhoneNumber = useCallback(
    async (phoneNumber) => {
      var appVerifier = window.recaptchaVerifier;
  
      await AuthInstance.AskOTPCode(phoneNumber, appVerifier, getCodeForLink);
    },
    [AuthInstance],
  )

  async function updateUserCredentials(values) {
    const user = await AuthInstance.getUser();

    if (values.newPassword) {
      AuthInstance.changePassword(values.currentPassword, values.newPassword);
    }
    if (values.email !== user.email) {
      AuthInstance.changeEmail(values.currentPassword, values.email);
    }
  }

  function fireError(e){
    Swal.fire({
      title: 'Ups....',
      text: `something happened ${e}`,
      icon: 'error',
    })
  }
  
  async function updateUserInformation(dataObject) {
    if (user && user.uid && user.phoneNumber) {
      const informationResp = await db.uploadDoc(dataObject, `user-accounts`, `${user.uid}`);

      if (informationResp.error) {
        fireError(informationResp.error)
      }
    }
  }

  async function updateUserBank(dataObject) {
    if (user && user.uid) {
      const bankResp = await db.uploadDoc(dataObject, `user-accounts/${user.uid}/bank-accounts`, 'bank-account1');

      if (bankResp.error) {
        fireError(bankResp.error)
      }
    }
  }

  async function updateUserDocuments(dataObject) {
    if (user && user.uid && user.phoneNumber) {
      if (dataObject.ownersInsurancePolicy && dataObject.govermentIssuedID) {
        const ownersInsurancePolicyURL = await storage.uploadFileWithListener(`/users/${user.uid}/documents/ownersInsurancePolicy`, dataObject.ownersInsurancePolicy);
        const govermentIssuedIDURL = await storage.uploadFileWithListener(`/users/${user.uid}/documents/govermentIssuedID`, dataObject.govermentIssuedID);

        const documents = {
          ownersInsurancePolicy: ownersInsurancePolicyURL,
          govermentIssuedID: govermentIssuedIDURL,
        }

        const { error } = await db.uploadDoc(documents, `user-accounts/${user.uid}/bank-accounts`, 'documents');

        if (error) {
          fireError(error)
        }
      }
    }
  }

  async function getCodeForLink() {
    let verificationCode;
    const result = await Swal.fire({
      title: 'Enter the SMS code that we just sended',
      input: 'text',
      inputAttributes: {
        autocapitalize: 'off'
      },
      showCancelButton: true,
      confirmButtonText: 'Submit',
      showLoaderOnConfirm: true,
      preConfirm: (code) => {
        verificationCode = code;
      },
      allowOutsideClick: () => !Swal.isLoading()
    })
    
    if (result.value) {
      Swal.fire({
        icon: 'success', 
        title: 'Your phoneNumber has been verified',
      })
    }

    return verificationCode
  }

  
  //render userInformation
  useEffect(() => {
    if (user.email) {
      function getBankName(htmlCode) {
        const NameWord = htmlCode.indexOf('Name:');
        const tableStartIndex = htmlCode.indexOf('<td>', NameWord);
        const tableEndIndex = htmlCode.indexOf('</td>', tableStartIndex);
    
    
        return htmlCode.slice(tableStartIndex + 4, tableEndIndex)
      }
  
      async function routingNumberLookup(routingNumber) {
        try {
          const res = await fetch(`//www.usbanklocations.com/crn.php?q=${encodeURIComponent(routingNumber)}`);
          const data = await res.text();
          
          const bankName = getBankName(data);
    
          setBankRoutingInformation(() => data)
          setBankRoutingNumber(<div className="ublcrnpoweredby">powered by <a href={`//www.usbanklocations.com/routing-number-${routingNumber}.html`}>usbanklocations.com</a></div>);
          setBankName(bankName);
        } catch (error) {
          console.log(error);
        }
      }
  
      db.getDoc(`user-accounts/${user.uid}/bank-accounts`, 'bank-account1').then((doc) => {
        const formData = doc;
        let bankDataModel;
        if (formData) {
          bankDataModel = new BankData(
            {
              title: 'Edit your bank account',
              bankAN: formData.BankAN || 0,
              bankANC: formData.BankAN || 0,
              bankRN: formData.BankRN || 0,
              bankRNC: formData.BankRN || 0,
              bankMatches: formData.BankMatches || 0,
            }
          );
        }else{
          bankDataModel = new BankData(
            {
              title: 'Edit your bank account',
              bankAN: 0,
              bankANC: 0,
              bankRN: 0,
              bankRNC: 0,
              bankMatches: 0,
            }
          );
        }
        setBankDataForm(bankDataModel.getFormData(routingNumberLookup, bankName));
        setBankValidation(() => bankDataModel.getRegisterValidation());
        setLoading(false);
      })
    }
  }, [db, user.uid, bankName, user.email]);

  useEffect(() => {
    if (user.email) {
      setUserDataCredentials([
        {
          id: 'currentPassword',
          type: 'password',
          info: 'Current Password',
          value: '',
        },
        {
          type: 'email',
          id: 'email',
          info: 'Email',
          value: user.email,
        },
        {
          id: 'newPassword',
          type: 'password',
          info: 'New Password',
          value: '',
        },
      ])

      db.getDoc(`user-accounts`, `${user.uid}`).then((doc) => {
        const formData = doc;
        if (formData) {
          
        }
        const userDataModel = new UserData(
          {
            title: 'Edit your user information',
            ...formData,
          }
        );
        setUserDataForm(userDataModel.getFormData(verifyPhoneNumber));
        setUserDataValidation(() => userDataModel.getRegisterValidation);
      })
    }
  }, [user, db, verifyPhoneNumber]);

  // Render Captcha for number verification
  useEffect(() => {
    window.recaptchaVerifier = new AuthInstance.authMethod.RecaptchaVerifier(captchaRef.current || 'captcha', {
      'size': 'invisible',
      'callback': function(response) {
        console.log('Captcha Solved', response);
      }
    });

    window.recaptchaVerifier.render().then(function(widgetId) {
      window.recaptchaWidgetId = widgetId;
    })
  }, [AuthInstance.authMethod.RecaptchaVerifier]);

  // const poweredbyRoutingNumber = <div className="ublcrnpoweredby">powered by <a href="//www.usbanklocations.com/check-routing-number.php">usbanklocations.com</a></div>;
  const htmlComment = '<!-- TERMS OF USE 1. THE MATERIALS ARE PROVIDED "AS IS" AND WITHOUT WARRANTIES OF ANY KIND. 2. DO NOT REMOVE THE TEXT OF "powered by usbanklocations.com" 3. COPYRIGHT BELONGS TO USBANKLOCATIONS.COM 4. DO NOT REMOVE THE TERMS OF USE -->';
  return (
    <section className='RegisterUser'>
      { !loading && 
        <>
          <div className='titleSection'>
            <h2>Edit Profile</h2>
          </div>
          <div className='RegisterUser__form'>
            { userDataCredentials.length > 1 &&
              <DynamicForm
                dataForm={ userDataCredentials }
                onSubmit={ updateUserCredentials }
                messageForButton={ 'Update User Credentials' }
                validation= { credentialValidation }
              />
            }
          
            { userDataForm.length > 1 && userDataValidation &&
              <DynamicForm
                dataForm={ userDataForm }
                onSubmit={ updateUserInformation }
                messageForButton={ 'Update User' }
                validation= { userDataValidation }
              />
            }

            { bankDataForm.length > 1 && bankValidation &&
              <>
                {bankRoutingInformation && 
                  <>
                    <div dangerouslySetInnerHTML={{__html: htmlComment}}>
                    </div>
                  </>
                }
                <DynamicForm 
                  dataForm={ bankDataForm }
                  onSubmit={ updateUserBank }
                  messageForButton={ 'Update Bank Account' }
                  validation= { bankValidation }
                  actions= { bankRoutingNumber }
                />
                
              </>
            }

            <DynamicForm 
              dataForm={ documentsDataForm }
              onSubmit={ updateUserDocuments }
              messageForButton={ 'Update Documents' }
              aditionalComponent= { verifyPhoneNumber }
              validation= { documentsValidation }
            />
          </div>
        </>
      }
      <div id='captcha' ref={captchaRef}> 
      </div>

      {redirect &&
        <Redirect to={redirect} />
      }
    </section>
  );
}

export default EditUser;