import React, { useState, useEffect } from 'react';
import './Landing.css'
import { Link } from 'react-router-dom'

import { FireStoreService } from "../../services/firebase/firebaseService";

export default function Landing() {
  const [directories, setDirectories] = useState([]);
  const [directoriesElement, setDirectoriesElement] = useState([]);
  const [directorySearch, setDirectorySearch] = useState('');

  useEffect(() => {
    async function getDirectories(){
      const directories = await FireStoreService.getCollection('directories');
      return directories
    }

    getDirectories().then(directories => {
      setDirectories(() => 
        directories.map(directory => ({
            name: directory.name,
            url: directory.url,
            id: directory.id,
            isActive: true
          })
        )
      )
    })

    return () => {
      setDirectories([]);
    }
  }, []);

  useEffect(() => {
    setDirectoriesElement(() => 
      directories.map(directory => (
        <li key={directory.id} className={directory.isActive ? '' : 'closed'}>
          <Link to={`./${directory.url}`}>
            {directory.name}
          </Link>
        </li>
      ))
    )

    return () => {
      setDirectoriesElement([]);
    }
  }, [directories]);

  function handleDirectoryChange({value}){
    const inputValue = value.toLowerCase();

    setDirectories(prev =>
      prev.map(directory => {
        if (inputValue.length > 0) {
          if (!(inputValue.substring(0, inputValue.length) === directory.name.substring(0, inputValue.length).toLowerCase())) {
            return {...directory, isActive: false}
          } else {
            return {...directory, isActive: true}
          }
        } else {
          return {...directory, isActive: true}
        }
      })
    );

    setDirectorySearch(inputValue);
  }

  return (
    <section className='landingSection'>
      <div className='title'>
        <h2>Welcome to School Directory</h2>
        <h3>Meet new friends for your kids</h3>
      </div>

      <div className="select directoriesSelect">
        <input onChange={(event) => handleDirectoryChange(event.target)} value={directorySearch} className="chosen-value" type="text" placeholder="Write the name of your's child school" />
        <ul className="value-list open" id="serverList">
          {directoriesElement}
        </ul>
      </div>

      <footer>
        <h4><Link to='./addDirectory'>Can't find your school? Ask your school to sign up with this link</Link></h4>
      </footer>
    </section>
  );
}