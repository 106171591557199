import React, { useEffect, useState } from 'react';
import DynamicForm from '../../components/dynamic_form/dynamic_form';
import Swal from 'sweetalert2';
import { StorageService, FireStoreService } from '../../services/firebase/firebaseService';
import './Advertise.css';
import { Redirect } from 'react-router-dom';

export default function () {
  const [redirect, setRedirect] = useState('')
  const dataForm = [
    {
      id: 'firstname',
      type: 'string',
      info: 'FirstName',
      hint: 'FirstName',
      value: '',
    },
    {
      id: 'lastname',
      type: 'string',
      info: 'LastName',
      hint: 'LastName',
      value: ''
    },
    {
      id: 'businessName',
      type: 'string',
      info: 'Business Name',
      hint: 'Business Name',
      value: ''
    },
    {
      id: 'businessLicense',
      type: 'string',
      info: 'Business License',
      value: '',
      hint: 'Business License'
    },
    {
      type: 'email',
      value: '',
      id: 'email',
      info: 'Email',
      hint: 'Email'
    },
    {
      type: 'email',
      value: '',
      id: 'emailConfirmation',
      info: 'Email Confirmation',
      hint: 'Email'
    },
    {
      id: 'phoneNumber',
      type: 'phoneNumber',
      info: 'Mobile Phone',
      // className: 'dynamic_form__input__withButton',
      hint: '+1 555 555 5555',
      value: '',
      // onClickEvent: verifyPhoneNumber,
      // buttonText: 'Verify Phone Number'
    },
    {
      id: 'adPicture',
      type: 'file',
      info: 'Advertise image',
      value: '',
      fileType: '.jpg'
    },
    {
      id: 'creditCardNumber',
      type: 'string',
      info: 'number',
      hint: '122222222222',
      value: ''
    },
    {
      id: 'cvv',
      type: 'number',
      info: 'CVV',
      hint: '123',
      value: ''
    },
    {
      id: 'expires',
      type: 'date',
      info: 'Expires',
      value: ''
    },
    {
      id: 'monthlyBilling',
      type: 'boolean',
      info: 'Recurring MONTHLY billing',
      value: '',
    },
  ];

  const formValidation = (values) => {
    let errors = {}

    if (!values.firstname) {
      errors.firstname = 'Firstname is required!';
    } else if (values.firstname.length <= 1) {
      errors.firstname = 'Firstname has to be 1 character at less!';
    }
    if (!values.lastname) {
      errors.lastname = 'Lastname is required!';
    } else if (values.lastname.length <= 1) {
      errors.lastname = 'Lastname has to be 1 character at less!';
    }
  
    if (!values.phoneNumber) {
      errors.phoneNumber = 'Number is required!';
    }

    if (!values.email) {
      errors.email = 'Email is required!';
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
      errors.email = 'Invalid Email!';
    }

    if (!values.emailConfirmation) {
      errors.emailConfirmation = 'Email confirmation is required!';
    } else if (values.email !== values.emailConfirmation) {
      errors.email = 'Email and email confirmation must match';
    }

    if (!values.creditCardNumber) {
      errors.creditCardNumber = 'Credit card number is required'; 
    } else if (values.creditCardNumber.toString().length !== 16){
      errors.creditCardNumber = 'cvv must be 16 characters long';
    }

    if (!values.cvv) {
      errors.cvv = 'cvv is required';
    } else if (values.cvv.toString().length !== 3){
      errors.cvv = 'cvv must be 3 characters long';
    }

    return errors;
  }

  const askAdvertise = async (values, actions) => {
    Swal.fire(
      'Uploading add',
      'your advertise is uploading',
      'info'
    );
    const adResp = await FireStoreService.uploadDoc({...values, adPicture: ''}, '/ads', 'generatedKey');
    const adPicture = await StorageService.uploadFileWithListener(`/ads/${adResp.resp}`, values.adPicture);
    const fullAdvertise = await FireStoreService.updateDoc({...values, adPicture: adPicture}, '/ads', adResp.resp);
    Swal.close();
    
    if (!fullAdvertise.error) {
      Swal.fire(
        'Advertise uploaded',
        'It will be checked by the admins',
        'success'
      )
      setRedirect('/login');
    } else {
      Swal.fire(
        'There were a problem',
        'Please, try again or contact SAGE Parents',
        'error'
      )
    }
  }

  return (
    <section className='ads'>
      <div className='title'>
        <h1>Advertise with Sage Parents Association</h1>
      </div>
      <div className='ads__form'>
        <DynamicForm
          dataForm={ dataForm }
          onSubmit={ askAdvertise }
          messageForButton={ 'Advertise with us' }
          validation= { formValidation }
        />
      </div>
      {redirect && 
        <Redirect to={redirect} />
      }
    </section>
  )    
};