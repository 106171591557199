import React, { useState } from 'react';
import Swal from 'sweetalert2';
import DynamicForm from '../../components/dynamic_form/dynamic_form';
import { AuthService } from '../../services/firebase/firebaseService';

function Forgot() {
  const [dataForm] = useState([
    {
      type: 'email',
      value: '',
      id: 'email',
      info: 'Email',
      hint: 'Email'
    },
  ]);
  async function forgotPassword({email}) {
    try {
      console.log(email);
      const value = await AuthService.resetPassword(email);
  
      if (value === 'All good') {
        Swal.fire({
          icon: 'success',
          title: 'Check your email',
        })
      }
    } catch (error) {
      
    }
  }

  const validation = (values) => {
    let errors = {}

    if (!values.email) {
      errors.email = 'Email is required';
    }

    return errors
  }

  return (
    <section className='login'>
      <div className='login__form'>
        <h1>
          Forgot your password?
        </h1>
        <DynamicForm 
          dataForm={ dataForm }
          onSubmit={ forgotPassword }
          messageForButton={'Send email'}
          validation= { validation }
        />
      </div>
    </section>
  )
}

export default Forgot