export default class UserData {
  title;
  bankAN;
  bankANC;
  bankRN;
  bankRNC;
  state;

  constructor(data){
    this.title = data.title || '';
    this.bankAN = data.bankAN || '';
    this.bankANC = data.bankANC || '';
    this.bankRN = data.bankRN || '';
    this.bankRNC = data.bankRNC || '';
    this.bankMatch = data.bankMatch || '';
  }

  getFormData(bankRoutingNumberLookup, bankName){
    const dataForm = [
      {
        id: 'sizedBank',
        type: 'sizedBoxWithTitle',
        info: this.title,
      },
      {
        id: 'BankRN',
        type: 'number',
        info: 'Bank Routing Number',
        value: this.bankRN,
        shouldMatch: 'BankRNC',
        className: 'dynamic_form__input__withButton',
        buttonText: 'Verify Bank Routing Number',
        onClickEvent: bankRoutingNumberLookup,
      },
      {
        id: 'BankRNC',
        type: 'number',
        info: 'Bank Routing Number Confirmation',
        value: this.bankRNC,
        shouldMatch: 'BankRN'
      },
      {
        id: 'BankAN',
        type: 'number',
        info: 'Bank Account Number',
        value: this.bankAN,
        shouldMatch: 'BankANC'
      },
      {
        id: 'BankANC',
        type: 'number',
        info: 'Bank Account Number Confirmation',
        value: this.bankANC,
        shouldMatch: 'BankAN'
      },
      {
        id: 'BankMatch',
        type: 'boolean',
        info: 'Bank Name Matches',
        checkBoxLabel: bankName,
        value: this.bankMatch,
      },
    ];

    return dataForm;
  }

  getRegisterValidation(){
    return (values) => {
      //Function to check routing number
      function ABARoutingNumberIsValid(routingNumberToTest) {
        if (!routingNumberToTest) { //all 0's is technically a valid routing number, but it's inactive
          return false;
        }
      
        var routing = routingNumberToTest.toString();
        while (routing.length < 9) {
          routing = '0' + routing; //I refuse to import left-pad for this
        }
      
        //gotta be 9  digits
        var match = routing.match("^\\d{9}$");
        if (!match) {
          return false;
        }
      
        //The first two digits of the nine digit RTN must be in the ranges 00 through 12, 21 through 32, 61 through 72, or 80.
        //https://en.wikipedia.org/wiki/Routing_transit_number
        const firstTwo = parseInt(routing.substring(0, 2));
        const firstTwoValid =  (0 <= firstTwo && firstTwo <= 12)
                            || (21 <= firstTwo && firstTwo <= 32)
                            || (61 <= firstTwo && firstTwo <= 72)
                            || firstTwo === 80;
        if (!firstTwoValid) {
          return false;
        }
      
        //this is the checksum
        //http://www.siccolo.com/Articles/SQLScripts/how-to-create-sql-to-calculate-routing-check-digit.html
        const weights = [3, 7 ,1];
        var sum = 0;
        for (var i=0 ; i<8; i++) {
          sum += parseInt(routing[i]) * weights[i % 3];
        }
      
        return (10 - (sum % 10)) % 10 === parseInt(routing[8]);
      }
      let errors = {};
      
      if (values) {
        if (!values.BankAN) {
          errors.BankAN = 'Bank Account Number is required!';
        }else if (values.BankAN.length < 2) {
          errors.BankAN = 'Bank Account Number has to be 2 character at less!';
        }else if (values.BankAN !== values.BankANC){
          errors.BankAN = 'Bank Account Number and Bank Account Number Confirmation must match!';
        }
      
        if (!values.BankANC) {
          errors.BankANC = 'Bank Account Number is required!';
        }else if (values.BankAN !== values.BankANC){
          errors.BankANC = 'Bank Account Number Confirmation and Bank Account Number must match!';
        }
      
        if (!values.BankRN) {
          errors.BankRN = 'Bank Routing Number is required!';
        }else if (values.BankRN.length === 9) {
          errors.BankRN = 'Bank Routing Number has to be 9 character long!';
        }else if (values.BankRN !== values.BankRNC){
          errors.BankRN = 'Bank Routing Number and Bank Routing Number Confirmation must match!';
        }else if(!ABARoutingNumberIsValid(values.BankRN)){
          errors.BankRN = 'Bank Routing Number is not a valid routing number!';
        }
      
        if (!values.BankRNC) {
          errors.BankRNC = 'Bank Routing Number Confimation is required!';
        }else if (values.BankRN !== values.BankRNC){
          errors.BankRNC = 'Bank Routing Number Confirmation and Bank Routing Number must match!';
        }

        if (values.BankMatch[0] !== 'on') {
          errors.BankMatch = "Bank name should match"
        }
      }
      return errors;
    }
   }

  getEditValidation(values){
    let errors = {};
    if (values) {
      if (!values.BankAN) {
        errors.BankAN = 'Bank Account Number is required!';
      }else if (values.BankAN.length < 2) {
        errors.BankAN = 'Bank Account Number has to be 2 character at less!';
      }else if (values.BankAN !== values.BankANC){
        errors.BankAN = 'Bank Account Number and Bank Account Number Confirmation must match!';
      }
    
      if (!values.BankANC) {
        errors.BankANC = 'Bank Account Number is required!';
      }else if (values.BankAN !== values.BankANC){
        errors.BankANC = 'Bank Account Number Confirmation and Bank Account Number must match!';
      }
    
      if (!values.BankRN) {
        errors.BankRN = 'Bank Routing Number is required!';
      }else if (values.BankRN.length < 2) {
        errors.BankRN = 'Bank Routing Number has to be 2 character at less!';
      }else if (values.BankRN !== values.BankRNC){
        errors.BankRN = 'Bank Routing Number and Bank Routing Number Confirmation must match!';
      }
    
      if (!values.BankRNC) {
        errors.BankRNC = 'Bank Routing Number Confimation is required!';
      }else if (values.BankRN !== values.BankRNC){
        errors.BankRNC = 'Bank Routing Number Confirmation and Bank Routing Number must match!';
      }
    }
    return errors;
  }
}