import React, { useState } from 'react';
import { FireStoreService } from '../../services/firebase/firebaseService';
import DynamicForm from '../dynamic_form/dynamic_form';
import { useSelector } from 'react-redux';
import './AddChild.css';

// eslint-disable-next-line import/no-anonymous-default-export
export default ({ sweetAlert, initialData, school }) => {
  const [formData] = useState([
    {
      id: 'name',
      type: 'string',
      info: 'Name',
      hint: 'Student Name',
      value: initialData.name ?? '',
    },
    {
      id: 'grade',
      type: 'listValues2',
      options: [
        {
          info: '6th',
          value: '6th'
        },
        {
          info: '7th',
          value: '7th'
        },
        {
          info: '8th',
          value: '8th'
        }
      ],
      value: initialData.grade ?? '',
      info: 'Grade'
    },
    {
      id: 'favoriteCause',
      type: 'string',
      value: initialData.favoriteCause ?? '',
      info: 'Favorite Cause/Carity'
    },
    {
      id: 'videoGames',
      type: 'string',
      value: initialData.videoGames ?? '',
      hint: 'Roblox, Minecraft, Overwatch...',
      info: 'Video Games'
    },
    {
      id: 'devices',
      type: 'string',
      value: initialData.devices ?? '',
      hint: 'Xbox, Iphone, Android...',
      info: 'Devices'
    },
    {
      id: 'sports',
      type: 'string',
      value: initialData.sports ?? '',
      hint: 'Baseball, Basketball, Soccer...',
      info: 'sports Play'
    },
  ]);

  async function handleSubmit(data) {
    try {
      const newData = {
        ...data,
        school
      }
      const resp = await FireStoreService.updateDoc(newData, 'children', initialData.id);

      if (resp) {
        sweetAlert.clickConfirm();
      }
    } catch (err) {
      throw err;
    }
  }

  function validation(data) {
    const errors = {};

    if (!data.videoGames || data.videoGames.length < 1) {
      errors.videoGames = 'You have to mention at least 1 videogame';
    }
    if (!data.devices || data.devices.length < 1) {
      errors.devices = 'You have to mention at least 1 device';
    }
    if (!data.name) {
      errors.name = 'Name is required';
    }

    return errors;
  }

  return (
    <div className='addChild'>
      <h1>Edit student</h1>
      <DynamicForm
        dataForm={formData}
        onSubmit={handleSubmit}
        messageForButton='Edit child'
        validation={validation}
      />
    </div>
  );
};