import React, { useState } from 'react';
import { FireStoreService } from '../../services/firebase/firebaseService';
import DynamicForm from '../../components/dynamic_form/dynamic_form';
import { useSelector } from 'react-redux';
import './AddChild.css';

// eslint-disable-next-line import/no-anonymous-default-export
export default ({ sweetAlert, LeaderUID, school }) => {
  const [fromOriginData, ] = useState([
    {
      id: 'firstname',
      type: 'string',
      info: 'FirstName',
      hint: 'Jhon',
      value: "",
    },
    {
      id: 'lastname',
      type: 'string',
      info: 'Lastname',
      hint: 'Doe',
      value: "",
    },
    {
      id: 'email',
      type: 'string',
      value: '',
      hint: 'email@example.com',
      info: 'Email'
    },
    {
      id: 'address',
      type: 'string',
      value: '',
      hint: '23 street',
      info: 'Address'
    },
    {
      id: 'phoneNumber',
      type: 'phoneNumber',
      info: 'Mobile Phone',
      // className: 'dynamic_form__input__withButton',
      hint: '+1 555 555 5555',
      value: "",
      // onClickEvent: verifyPhoneNumber,
      // buttonText: 'Verify Phone Number'
    },
  ]);

  async function handleSubmit(data) {
    try {
      console.log('Submit from Origin', data);
      // const resp = await FireStoreService.uploadDoc(data, 'animales', 'generatedKey');

      const newData = {
        name: `${data.firstname} ${data.lastname}`,
        phoneNumber: data.phoneNumber,
        email: data.email,
        address: data.address,
        LeaderUID: LeaderUID ? LeaderUID : 'NotLOGGED',
        school: school
      }

      const resp = await FireStoreService.uploadDoc(newData, 'guardians', 'generatedKey');

      console.log('Doc Created', resp);
      if (resp) {
        sweetAlert.clickConfirm();
      }
    } catch (err) {
      throw err;
    }
  }

  function validation(values) {
    let errors = {};
   
    if (!values.firstname) {
      errors.firstname = 'Firstname is required!';
    } else if (values.firstname.length <= 1) {
      errors.firstname = 'Firstname has to be 1 character at less!';
    }
    if (!values.lastname) {
      errors.lastname = 'Lastname is required!';
    } else if (values.lastname.length <= 1) {
      errors.lastname = 'Lastname has to be 1 character at less!';
    }
  
    if (!values.phoneNumber) {
      errors.phoneNumber = 'PhoneNumber is required!';
    }

    return errors;
  }

  return (
    <div className='addChild'>
      <h1>This is for adding a guardian</h1>
      <DynamicForm
        dataForm={fromOriginData}
        onSubmit={handleSubmit}
        messageForButton='Add guardian'
        validation={validation}
      />
    </div>
  );
};