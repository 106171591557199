import React, {useState, useEffect} from 'react';
import { FireStoreService } from "../../services/firebase/firebaseService";

// import './Family.css'
import { useSelector } from 'react-redux';
import { columnsGuardiansForSystem } from '../../models/columns'
import DataTable from 'react-data-table-component';

export default function Garages() {
  const db = FireStoreService;
  const userUID = useSelector(state => state.user.uid);
  const [parents, setParents] = useState([]);
  // const [newChild, setNewChild] = useState();

  useEffect(() => {
    if (userUID !== 'none') {
      function addParent(parent){
        setParents(prev => ([
          ...prev,
          {
            ...parent.data(),
            id: parent.id,
          }
        ]))
      }

      function modifyParent(parent) {
        setParents((prev) => {
          prev.map((localParent) => {
            if (localParent.id === parent.id) {
              return {
                ...parent.data(),
                id: parent.id,
              }
            } else {
              return localParent
            }
          })
        })
      }

      function removeParent(parent){
        setParents(prev => {
          prev.filter((localParent) => localParent.id !== parent.id); 
        });
      }

      db.getRealTimeQuery('guardians', ['accountAproved', '==', false], addParent, modifyParent, removeParent);
    }
  }, [userUID, db]);

  const customStyles = {
    headCells: {
      style: {
        fontSize: '16px',
      }
    },
    rows: {
      style: {
        fontSize: '16px',
      }
    },
  }

  return (
    <section className='family'>
      <div className='titleSection'>
        <h2>Directory settings</h2>
      </div>
      <div className='familyContainer'>
        <DataTable
          title='Directory data'
          columns={columnsGuardiansForSystem}
          data={parents}
          customStyles={customStyles}
        />
      </div>
      <div className='familyContainer'>
        <DataTable
          title='Parents/Guardians Accounts'
          columns={columnsGuardiansForSystem}
          data={parents}
          customStyles={customStyles}
        />
      </div>
    </section>
  );
}