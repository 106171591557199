import React, {useState} from 'react';
import { Link, useHistory }from 'react-router-dom'
import './Header.css'
import { useSelector } from 'react-redux';
import { AuthService } from '../../services/firebase/firebaseService';
import Swal from 'sweetalert2';

function Home () {
  const user = useSelector(state => state.user);
  let history = useHistory()
  const [isDropdownActive, setIsDropdownActive] = useState(false);

  console.log(user);
  async function signOut(){
    const auth = AuthService;
    const { error } = await auth.signOut(); 

    if (error) {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: `Something happened loggin you out ${error.message} please try again`,
      });
    } else {
      history.push('/');
      Swal.fire({
        icon: 'success',
        title: 'Logged out',
      });
    }
  }

  return (
    <header>
      <div className='Logo'>
        <Link to='/'>
          <h1>
            School Directory
          </h1>
        </Link>
        <div className='nav_signButtons'>
          {
            user.uid !== 'none'
            ?
              <div className='nav__user'>
                <div className='nav__user__icons loginButton' onClick={() => setIsDropdownActive(prev => !prev)}>
                  <span className="material-icons">person</span>
                  <span className="material-icons" id='navigatorArrow'>keyboard_arrow_down</span>
                </div>
                <nav className={`nav__user__dropdown ${isDropdownActive ? 'nav__user__dropdown-active' : ''}`}>
                  <Link to='/family' className='navigator__button'>
                    <span className="material-icons">house</span>
                    <span>My Family</span>
                  </Link>
                  <Link to='/search' className='navigator__button'>
                    <span className="material-icons">search</span>
                    <span>Search</span>
                  </Link>
                  { user.accessLevel &&
                    <Link to='/directory' className='navigator__button'>
                      <span className="material-icons">menu_book</span>
                      <span>Admin directory</span>
                    </Link>
                  }
                  { user.accessLevel > 4 &&
                    <Link to='/system' className='navigator__button'>
                      <span className="material-icons">menu_book</span>
                      <span>System</span>
                    </Link>
                  }
                  <span onClick={ signOut } className='navigator__button'>
                    <span className="material-icons">input</span>
                    <span>Sign Out</span>
                  </span>
                </nav>
              </div>
            :
              <>
                <Link to='/sign_up' className='loginButton'>Sign Up</Link>
                <Link to='/login' className='loginButton'>Log In</Link>
              </>
          }
        </div>
      </div>
      <div className='nav'>
        <div className='advert'>
          <span>3rd Party Advert</span>
          <Link to='/'>Details 1</Link>
          <Link to='/'>Details 2</Link>
        </div>
      </div>
    </header>
  );
};

export default Home;