import React from 'react'
import './Loading.css'
// import garageLoading from '../../../public/assets/Images/GarageAnimation.svg'

export default function loading({text}){
  return (
    <div className='loading'>
      <svg
        xmlSpace="preserve"
        viewBox="0 0 100 100"
        y="0px"
        x="0px"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        xmlns="http://www.w3.org/2000/svg"
        id="圖層_1"
        version="1.1"
        style={{height: "100%", width: "100%", background: "rgba(241, 242, 243, 0)"}}
        width="200px"
        height="200px"
      >
        <style type="text/css">
          {
            `/* <![CDATA[ */
            @keyframes breath {
              0% {
                animation-timing-function: cubic-bezier(0.9647,0.2413,-0.0705,0.7911);
                transform: scale(0.9099999999999999);
              }
              51% {
                  animation-timing-function: cubic-bezier(0.9226,0.2631,-0.0308,0.7628);
                  transform: scale(1.02994);
              }
              100% {
                transform: scale(0.9099999999999999);
              }
            }
            .secondary{
              // storke: rgb(53, 53, 47) !important;
              stroke: #e7e7de !important;
            }
            /* ]]> */`

          }
        </style>
        <g
          className="ldl-scale"
          style={{transformOrigin: "50% 50%", transform: "rotate(0deg) scale(1, 1)"}}
        >
          <g
            className="ldl-ani"
            style={{
              transformOrigin: "50px 50px",
              transform: "scale(0.91)",
              animation: "1.53846s linear 0s infinite normal forwards running breath",
            }}
          >
            <g className="ldl-layer">
              <g className="ldl-ani" style={{transformOrigin: "50px 50px"}}>
                <path
                  d="M75,43.7c0,18.4-25,31.4-25,31.4s-25-13-25-31.4s25-18.3,25-0.7C50,25.7,75,25.3,75,43.7z"
                  strokeMiterlimit="10"
                  strokeLinejoin="round"
                  strokeLinecap="round"
                  strokeWidth="3.5"
                  // stroke="#333435"
                  className='secondary'
                  fill="#C33837"
                  style={{fill: "rgb(195, 56, 55)", stroke: "rgb(53, 53, 47)"}}
                ></path>
              </g>
            </g>
            <g className="ldl-layer">
              <g className="ldl-ani">
                <g>
                  <g className="ldl-layer">
                    <g className="ldl-ani" style={{transformOrigin: "50px 50px"}}>
                      <line
                        y2="16.5"
                        x2="50"
                        y1="8"
                        x1="50"
                        strokeMiterlimit="10"
                        strokeLinejoin="round"
                        strokeLinecap="round"
                        strokeWidth="3.5"
                        // stroke="#333435"
                        fill="none"
                        className='secondary'
                        style={{stroke: "rgb(53, 53, 47)"}}
                      ></line>
                    </g>
                  </g>
                  <g className="ldl-layer">
                    <g className="ldl-ani" style={{transformOrigin: "50px 50px"}}>
                      <line
                        y2="26.3"
                        x2="26.3"
                        y1="20.3"
                        x1="20.3"
                        strokeMiterlimit="10"
                        strokeLinejoin="round"
                        strokeLinecap="round"
                        strokeWidth="3.5"
                        stroke="#333435"
                        fill="none"
                        className='secondary'
                        style={{stroke: "rgb(53, 53, 47)"}}
                      ></line>
                    </g>
                  </g>
                  <g className="ldl-layer">
                    <g className="ldl-ani" style={{transformOrigin: "50px 50px"}}>
                      <line
                        y2="50"
                        x2="16.5"
                        y1="50"
                        x1="8"
                        strokeMiterlimit="10"
                        strokeLinejoin="round"
                        strokeLinecap="round"
                        strokeWidth="3.5"
                        stroke="#333435"
                        className='secondary'
                        style={{stroke: "rgb(53, 53, 47)"}}
                      ></line>
                    </g>
                  </g>
                  <g className="ldl-layer">
                    <g className="ldl-ani" style={{transformOrigin: "50px 50px"}}>
                      <line
                        y2="73.7"
                        x2="26.3"
                        y1="79.7"
                        x1="20.3"
                        strokeMiterlimit="10"
                        strokeLinejoin="round"
                        strokeLinecap="round"
                        strokeWidth="3.5"
                        stroke="#333435"
                        fill="none"
                        className='secondary'
                        style={{stroke: "rgb(53, 53, 47)"}}
                      ></line>
                    </g>
                  </g>
                  <g className="ldl-layer">
                    <g className="ldl-ani" style={{transformOrigin: "50px 50px"}}>
                      <line
                        y2="83.5"
                        x2="50"
                        y1="92"
                        x1="50"
                        strokeMiterlimit="10"
                        strokeLinejoin="round"
                        strokeLinecap="round"
                        strokeWidth="3.5"
                        stroke="#333435"
                        fill="none"
                        className='secondary'
                        style={{stroke: "rgb(53, 53, 47)"}}
                      ></line>
                    </g>
                  </g>
                  <g className="ldl-layer">
                    <g className="ldl-ani" style={{transformOrigin: "50px 50px"}}>
                      <line
                        y2="73.7"
                        x2="73.7"
                        y1="79.7"
                        x1="79.7"
                        strokeMiterlimit="10"
                        strokeLinejoin="round"
                        strokeLinecap="round"
                        strokeWidth="3.5"
                        stroke="#333435"
                        fill="none"
                        className='secondary'
                        style={{stroke: "rgb(53, 53, 47)"}}
                      ></line>
                    </g>
                  </g>
                  <g className="ldl-layer">
                    <g className="ldl-ani" style={{transformOrigin: "50px 50px"}}>
                      <line
                        y2="50"
                        x2="83.5"
                        y1="50"
                        x1="92"
                        strokeMiterlimit="10"
                        strokeLinejoin="round"
                        strokeLinecap="round"
                        strokeWidth="3.5"
                        stroke="#333435"
                        fill="none"
                        className='secondary'
                        style={{stroke: "rgb(53, 53, 47)"}}
                      ></line>
                    </g>
                  </g>
                  <g className="ldl-layer">
                    <g className="ldl-ani" style={{transformOrigin: "50px 50px"}}>
                      <line
                        y2="26.3"
                        x2="73.7"
                        y1="20.3"
                        x1="79.7"
                        strokeMiterlimit="10"
                        strokeLinejoin="round"
                        strokeLinecap="round"
                        strokeWidth="3.5"
                        stroke="#333435"
                        fill="none"
                        className='secondary'
                        style={{stroke: "rgb(53, 53, 47)"}}
                      ></line>
                    </g>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </svg>
      <span>{text || 'Loading, Please Wait...'}</span>
    </div>
  )
}