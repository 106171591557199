import React, { useState } from 'react';
import { FireStoreService } from '../../services/firebase/firebaseService';
import DynamicForm from '../../components/dynamic_form/dynamic_form';
import { useSelector } from 'react-redux';
import './AddChild.css';

// eslint-disable-next-line import/no-anonymous-default-export
export default ({ sweetAlert, parentUID, school }) => {
  const [fromOriginData, ] = useState([
    {
      id: 'name',
      type: 'string',
      info: 'Name',
      hint: 'Student Name',
      value: '',
    },
    {
      id: 'grade',
      type: 'listValues2',
      options: [
        {
          info: '6th',
          value: '6th'
        },
        {
          info: '7th',
          value: '7th'
        },
        {
          info: '8th',
          value: '8th'
        }
      ],
      value: '',
      info: 'Grade'
    },
    {
      id: 'favoriteCause',
      type: 'string',
      value: '',
      info: 'Favorite Cause/Carity'
    },
    {
      id: 'videoGames',
      type: 'string',
      value: '',
      hint: 'Roblox, Minecraft, Overwatch...',
      info: 'Video Games'
    },
    {
      id: 'devices',
      type: 'string',
      value: '',
      hint: 'Xbox, Iphone, Android...',
      info: 'Devices'
    },
    {
      id: 'sports',
      type: 'string',
      value: '',
      hint: 'Baseball, Basketball, Soccer...',
      info: 'sports Play'
    },
  ]);

  async function handleSubmit(data) {
    try {
      console.log('Submit from Origin', data);
      // const resp = await FireStoreService.uploadDoc(data, 'animales', 'generatedKey');

      const newData = {
        ...data,
        parentUID: parentUID ? parentUID : 'NotLOGGED',
        school: school
      }
      console.log(newData);

      const resp = await FireStoreService.uploadDoc(newData, 'children', 'generatedKey');

      console.log('Doc Created', resp);
      if (resp) {
        sweetAlert.clickConfirm();
      }
    } catch (err) {
      throw err;
    }
  }

  function validation(data) {
    const errors = {};

    if (!data.videoGames || data.videoGames.length < 1) {
      errors.videoGames = 'You have to mention at least 1 videogame';
    }
    if (!data.devices || data.devices.length < 1) {
      errors.devices = 'You have to mention at least 1 device';
    }
    if (!data.name) {
      errors.name = 'Name is required';
    }

    return errors;
  }

  return (
    <div className='addChild'>
      <h1>This is for adding a student</h1>
      <DynamicForm
        dataForm={fromOriginData}
        onSubmit={handleSubmit}
        messageForButton='Add student'
        validation={validation}
      />
    </div>
  );
};