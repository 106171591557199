import XLSX from 'xlsx';

export function readXLSXFromFile(file, readyFunction) {
  const reader = new FileReader();
  reader.onload = (e) => {
    const data = new Uint8Array(e.target.result);
    const workbook = XLSX.read(data, {type: 'array'});

    /* DO SOMETHING WITH workbook HERE */
    const first_sheet_name = workbook.SheetNames[0];

    /* Get worksheet */
    const worksheet = workbook.Sheets[first_sheet_name];

    const JSON = XLSX.utils.sheet_to_json(worksheet, {
      raw: true
    })
    if (readyFunction) {
      readyFunction(JSON)
    }
  }

  if (file) {
    reader.readAsArrayBuffer(file);
  } else {
    return false
  }
}

export function writeXLSXfromJSON(JSON, SheetName, workName) {
  const workbook = XLSX.utils.book_new();
  const Sheet = XLSX.utils.json_to_sheet(JSON);
  XLSX.utils.book_append_sheet(workbook, Sheet, SheetName || 'sheet');
  XLSX.writeFile(workbook, `${workName}.xlsx`);
}