// * Options for searching

export const searchOptionsGuardians = [
  {
    info: 'All',
    value: 'all'
  }
];

export const searchOptionsChildren = [
  {
    info: 'All',
    value: 'all'
  }
];

// * Values for options and filters

export const searchValuesGuardians = {
  'searchByValue': {
    collection: 'guardians',
    filters: [
      {
        condition: '==',
        field: 'name',
        value: 'givedByUser'
      }
    ]
  }
};

export const searchValuesChildren = {
  'all': {
    collection: 'children',
    filters: []
  },
  'searchByValue': {
    collection: 'children',
    filters: [
      {
        condition: '==',
        field: 'name',
        value: 'givedByUser'
      }
    ]
  }
};