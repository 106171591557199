import React, {useState, useEffect} from 'react';
import { FireStoreService } from "../../services/firebase/firebaseService";

import './Family.css'
import { useSelector } from 'react-redux';
import { renderComponentInSwal } from '../../services/swal/renderMyComponent';
import AddChild from '../../components/addChild/AddChild';
import AddGuardian from '../../components/addGuardian/AddGuardian';
import { columnsGuardians, columnsChildren } from '../../models/columns'
import DataTable from 'react-data-table-component';

export default function Garages() {
  const db = FireStoreService;
  const userUID = useSelector(state => state.user.uid);
  const school = useSelector(state => state.user.school);
  const [parents, setParents] = useState([]);
  const [children, setChildren] = useState([]);

  useEffect(() => {
    if (userUID !== 'none') {
      function addParent(parent){
        setParents(prev => ([
          ...prev,
          {
            ...parent.data(),
            id: parent.id,
          }
        ]))
      }

      function modifyParent(parent) {
        setParents(prev => prev.map(localParent => {
          if (localParent.id !== parent.id) return localParent;
          
          return {
            ...parent.data(),
            id: parent.id,
          }
        }));
      }

      function removeParent(parent){
        setParents(prev => prev.filter(localParent => localParent.id !== parent.id));
      }

      function addChild(child){
        setChildren(prev => [
          ...prev,
          {...child.data(), id: child.id}
        ]);
      }

      function modifyChild(child) {
        setChildren(prev => prev.map(localChild => {
          if (localChild.id !== child.id) return localChild 

          return {
            ...child.data(),
            id: child.id,
          }
        }));
      }

      function removeChild(child){
        setChildren(prev => prev.filter(localChild => localChild.id !== child.id ));
      }

      db.getRealTimeQuery('guardians', ['LeaderUID', '==', userUID], addParent, modifyParent, removeParent);
      db.getRealTimeQuery('children', ['parentUID', '==', userUID], addChild, modifyChild, removeChild);
    }
  }, [userUID, db]);

  function addChild() {
    renderComponentInSwal(<AddChild school={school} parentUID={userUID} />, 'child added', 'the child has been added');
  }

  function addGuardian() {
    renderComponentInSwal(<AddGuardian school={school} LeaderUID={userUID} />, 'Guardian added', 'the Guardian has been added');
  }

  const customStyles = {
    headCells: {
      style: {
        fontSize: '16px',
      }
    },
    rows: {
      style: {
        fontSize: '16px',
      }
    },
  }

  return (
    <section className='family'>
      <div className='titleSection'>
        <h2>Family</h2>
      </div>
      <div className='familyContainer'>        
        <DataTable
          title='Parents/Guardians'
          actions={<button className='buttonPrimary' onClick={addGuardian}>Add parent/guardian</button>}
          columns={columnsGuardians}
          data={parents}
          customStyles={customStyles}
        />
      </div>
      <div className='familyContainer'>        
        <DataTable
          title='Children'
          actions={<button className='buttonPrimary' onClick={addChild}>Add child</button>}
          columns={columnsChildren}
          data={children}
          customStyles={customStyles}
          // theme='dark'
        />
      </div>
    </section>
  );
}