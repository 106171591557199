import React, { useEffect, useState } from 'react';
import AdminUsers from '../../components/adminUsers/AdminUsers';
import Directories from '../../components/directories/Directories';
// import Swal from 'sweetalert2';
import './System.css';

// eslint-disable-next-line import/no-anonymous-default-export
export default () => {
  return (
    <section className='systemContainer'>
      <div className='titleSection'>
        <h1>Administrator</h1>
      </div>
      <div className='systemContainer'>
        <Directories />
      </div>
      <div className='systemContainer'>
        <AdminUsers />
      </div>
    </section>
  )
}