import React from 'react';
import './Sign.css'
import DynamicForm from '../../components/dynamic_form/dynamic_form';
import { AuthService } from '../../services/firebase/firebaseService';
import Swal from 'sweetalert2';
import { Link, useParams, useHistory } from 'react-router-dom';

function Sign({ isSignUp, ...props }) {
  console.log(useParams(), props);
  let history = useHistory();
  const auth = AuthService;
  const dataForm = [
    {
      type: 'email',
      value: '',
      id: 'email',
      info: 'Email',
      hint: 'Email'
    },
    {
      id: 'password',
      type: 'password',
      info: 'Password',
      hint: 'password',
      value: '',
    },
  ];

  function validation(values){
    let errors = {};
   
    if (!values.email) {
      errors.email = 'Email is required!';
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
      errors.email = 'Invalid Email!';
    }
    if (!values.password) {
      errors.password = 'Password is required!';
    } else if (values.password.length <= 4) {
      errors.password = 'Password has to be 4 character at less!';
    }
    return errors;
  }

  async function SignInwithEmailAndPassword(values){
    const {error} = isSignUp 
    ? await auth.SignUpWithEmailAndPassword(values) 
    : await auth.SignInWithEmailAndPassword(values);

    if (error) {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: `Something happened in the log ${error.message} please try again`,
      });
    } else {
      Swal.fire({
        icon: 'success',
        title: 'Logged in',
      });
      if(isSignUp){
        history.push('register');
      } else {
        history.push('/');
      }
    }
  }

  const forgotPasswordButton = 
    <Link
      key='forgotPassword'
      className='forgotButton'
      to='./forgot'
    >
      Forgot Password?
    </Link>;

  return (
    <section className='login'>
      <div className='ads'>
        SOME AD
      </div>
      <div className='login__form'>
        <h1>
          Log in School Directory
        </h1>

        <DynamicForm 
          dataForm={ dataForm }
          onSubmit={ SignInwithEmailAndPassword }
          messageForButton={'Log In'}
          validation= { validation }
          actions= { [forgotPasswordButton] }
        />
        <small className='advice'>
          Participation in this directory is completely optional. If you choose to complete the 
          questionaire your information will only be shared with other families
        </small>
        <Link to={isSignUp ? '/login' : '/sign_up'}>
          <h3 className='strong'>{isSignUp ? 'Already have an account? login' : "Don't have an account? join us"}</h3>
        </Link>
        <Link to='/advertise' className='adswithus'>
          Advertise with us!
        </Link>
      </div>
    </section>
  );
}

export default Sign;