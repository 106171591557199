import React, { useState } from 'react';
import { FireStoreService } from '../../services/firebase/firebaseService';
import DynamicForm from '../../components/dynamic_form/dynamic_form';
// import './AddChild.css';
import Swal from 'sweetalert2';

export default ({ sweetAlert }) => {
  const [fromOriginData, ] = useState([
    {
      id: 'name',
      type: 'string',
      info: 'Name',
      hint: 'Directory Name',
      value: '',
    },
    {
      id: 'EIN',
      type: 'string',
      value: '',
      hint: '12-9856779',
      info: 'EIN'
    },
    {
      id: 'url',
      type: 'string',
      value: '',
      hint: 'sagemagnet',
      info: 'URL'
    },
    {
      id: 'color',
      type: 'color',
      value: '',
      hint: '',
      info: 'Color'
    },
  ]);

  async function handleSubmit(data) {
    try {
      const newData = {
        ...data,
        name: data.name.toLowerCase(),
      }

      const resp = await FireStoreService.uploadDoc(newData, 'directories', 'generatedKey');

      // if (resp) {
        sweetAlert.clickConfirm();
      // }
    } catch (err) {
      Swal.showValidationMessage(
        `Error creating the directory: ${err}`
      )
      throw err;
    }
  }

  function validation(data) {
    const errors = {};

    if (!data.name || data.name.length < 1) {
      errors.name = 'Name is required';
    }
    if (!data.EIN || data.EIN.length < 1) {
      errors.EIND = 'EIN is required';
    }

    return errors;
  }

  return (
    <div className='addChild'>
      <h1>This is for adding a new Directory</h1>
      <DynamicForm
        dataForm={fromOriginData}
        onSubmit={handleSubmit}
        messageForButton='Add Directory'
        validation={validation}
      />
    </div>
  );
};