import React, { useState, useEffect } from 'react';
import { FireStoreService } from '../services/firebase/firebaseService';
import DataTable from 'react-data-table-component';
import { columnsGuardians } from './columns';

// * Columns

export const columnsForGuardians = [
  {
    name: 'Full Name',
    selector: 'name',
    sortable: true,
  },
  {
    name: 'Phone Number',
    selector: 'phoneNumber',
    sortable: true,
  },
  {
    name: 'Email',
    selector: 'email',
    sortable: true,
  },
  {
    name: 'Address',
    selector: 'address',
  }
];

export const columnsForChildren = [
  {
    name: 'Name',
    selector: 'name',
    sortable: true
  },
  {
    name: 'Grade',
    selector: 'grade',
    sortable: true
  },
  {
    name: 'Parent',
    selector: 'parentUID',
    cell: row => <MainParentCell id={row.parentUID} />
  },
  {
    name: 'Sports',
    selector: 'sports',
    sortable: true
  },
  {
    name: 'Video games',
    selector: 'videoGames',
    sortable: true
  }
]

// * Components

function MainParentCell({ id }) {
  const [name, setName] = useState(null);

  useEffect(() => {
    if (id)
      FireStoreService.getDoc('guardians', id)
        .then(resp => setName(() => resp ? `${resp.name}` : null))
  }, [id]);

  return (
    <span>{name ? name : id}</span>
  );
}

function ExpandableForGuardian({ data, expandable }) {
  const [children, setChildren] = useState([]);
  console.log('Guardian', data)
  useEffect(() => {
    const id = data.LeaderUID ? data.LeaderUID : data.id ? data.id : null;
    if (id)
      FireStoreService.getMultiFilterQueryDataAsArray('children', [['parentUID', '==', id]])
        .then(response => {
          console.log('CHILDREN', response);
          setChildren(() => response);
        }).catch(err => {
          console.log('Error handling children of guardian', err);
        })
  }, [data]);
  console.log(data);
  return (
    <div className="expandatableTable__container">
      <DataTable
        title='Children'
        columns={columnsForChildren}
        data={children}
        expandableRows={expandable ? expandable : false}
        expandableRowsComponent={<ExpandableForChild />}
      />
    </div>
  )
}

function ExpandableForChild({ data, expandable }) {
  const [guardians, setGuardians] = useState([]);
  console.log('data ------>', data);
  useEffect(() => {
    if (data.parentUID) {
      let results = [];
      FireStoreService.getDocWithid('guardians', data.parentUID)
      .then(res => {
        results = [...results, res];
        return FireStoreService.getMultiFilterQueryDataAsArray('guardians', [['LeaderUID', '==', data.parentUID]])
      }).then(response => {
        results = results.concat(response);
        console.log('CHILDREN', results);
        setGuardians(() => results);
      }).catch(err => {
        console.log('Error handling guardians of child on expandable componennt', err);
      });
    }
  }, [data]);
  console.log(data);
  return (
    <div className="expandatableTable__container">
      <DataTable
        title='Guardians'
        columns={columnsForGuardians}
        data={guardians}
        expandableRows={expandable ? expandable : false}
        expandableRowsComponent={<ExpandableForGuardian />}
      />
    </div>
  )
}

export const ExpandableForGuardianComponent = ExpandableForGuardian;
export const ExpandableForChildComponent = ExpandableForChild;