import React, { useEffect, useState } from 'react';
import { AuthService, FireStoreService } from '../../services/firebase/firebaseService';
import DynamicForm from '../../components/dynamic_form/dynamic_form';
import './AddChild.css';

// eslint-disable-next-line import/no-anonymous-default-export
export default ({ sweetAlert }) => {
  const [directories, setDirectories] = useState([]);
  const [formData, setFormData] = useState([]);

  async function handleSubmit(data) {
    try {
      const userToSetClaims = await FireStoreService.getQuery('guardians', ['email', '==', data.email]);
      
      let userID
      for (const key in userToSetClaims) {
        if (userToSetClaims.hasOwnProperty(key)) {
          // const element = userToSetClaims[key];
          userID = key
        }
      }

      const currentUserIDToken = await AuthService.getUser().getIdToken(true);
      
      const myHeaders = new Headers();
      myHeaders.append("Authorization", `Bearer ${currentUserIDToken}`);
      myHeaders.append("Content-Type", "application/json");
      
      const body = {
        "uid": userID,
        "level": parseInt(data.accessLevel),
        "school": data.directory,
        'email': data.email
      }
      console.log(body);
      
      const req = {
        method: 'POST',
        headers: myHeaders,
        body: JSON.stringify(body),
        redirect: 'follow'
      }

      const path = 'setAdminPermissions';

      const respPromise = await fetch(`https://us-central1-sage-120a5.cloudfunctions.net/server/${path}`, req);
      const resp = await respPromise.json();

      if (resp) {
        if (resp) {
          sweetAlert.clickConfirm();
        }
      }
    } catch (err) {
      throw err;
    }
  }

  function validation(data) {
    const errors = {};

    if (!data.email || data.email < 1) {
      errors.email = 'Email is required';
    }
    if (!data.accessLevel) {
      errors.accessLevel = 'Access Level is required';
    }
    if (!data.directory) {
      errors.directory = 'Directory is required';
    }

    return errors;
  }

  useEffect(() => {
    async function getDirectories() {
      try {
        const directories = await FireStoreService.getCollection('directories');
        setDirectories(() => directories.map((directory) => ({info: directory.name, value: directory.id})));
      } catch (error) {
        console.log(error);
      }
    }
    getDirectories();
  }, [])

  useEffect(() => {
    if (directories.length > 0) {
      console.log(directories);
      setFormData([
        {
          id: 'email',
          type: 'string',
          info: 'Email',
          hint: 'example@gmail.com',
          value: '',
        },
        {
          id: 'accessLevel',
          type: 'listValues',
          hint: '5: full access 4: Association',
          options: [
            {
              info: '5',
              value: 5
            },
            {
              info: '4',
              value: 4
            },
          ],
          value: '',
          info: 'Access Level'
        },
        {
          id: 'directory',
          type: 'listValues',
          options: directories,
          value: '',
          info: 'Directories'
        },
      ]);
    }
  }, [directories]);

  return (
    <div className='addChild'>
      <h1>Add Admin</h1>
      { formData.length > 1 &&
        <DynamicForm
          dataForm={formData}
          onSubmit={handleSubmit}
          messageForButton='Add admin'
          validation={validation}
        />
      }
    </div>
  );
};