import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import AccountAprovement from '../../components/accountAprovement/AccountAprovement'
import Advertisments from '../../components/advertisments/Advertisments';
import ImportExport from '../../components/importExport/ImportExportClients';
// import Swal from 'sweetalert2';
// import './System.css';

// eslint-disable-next-line import/no-anonymous-default-export
export default () => {
  const school = useSelector(state => state.user.school);

  return (
    <section className='systemContainer'>
      <div className='titleSection'>
        <h1>Directory settings</h1>
      </div>
      <div className='systemContainer'>
        <AccountAprovement />
      </div>
      <div className='ads'>
        <Advertisments />
      </div>
      <div>
        <ImportExport schoolID={school} />
      </div>
    </section>
  )
}