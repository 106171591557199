import React, { useEffect, useState } from 'react';
import DynamicForm from '../../components/dynamic_form/dynamic_form';
import { AuthService as AuthInstance, FireStoreService as db } from '../../services/firebase/firebaseService';
import './RegisterUser.css';
import { Redirect } from 'react-router-dom';
import Swal from 'sweetalert2';
import { useSelector } from 'react-redux';

function RegisterUser() {
  const captchaRef = React.useRef(null);
  const user = useSelector(state => state.user);
  const [redirect, setRedirect] = useState(false);
  const dataForm = [
    {
      id: 'firstname',
      type: 'string',
      info: 'FirstName',
      hint: 'FirstName',
      value: "",
    },
    {
      id: 'lastname',
      type: 'string',
      info: 'LastName',
      hint: 'LastName',
      value: "",
    },
    {
      id: 'address',
      type: 'string',
      info: 'Address',
      hint: "23 street",
      value: '',
    },
    {
      id: 'phoneNumber',
      type: 'phoneNumber',
      info: 'Mobile Phone',
      // className: 'dynamic_form__input__withButton',
      hint: '+1 555 555 5555',
      value: "",
      // onClickEvent: verifyPhoneNumber,
      // buttonText: 'Verify Phone Number'
    },
  ];

  function validation(values){
    let errors = {};
   
    if (!values.firstname) {
      errors.firstname = 'Firstname is required!';
    } else if (values.firstname.length <= 1) {
      errors.firstname = 'Firstname has to be 1 character at less!';
    }
    if (!values.lastname) {
      errors.lastname = 'Lastname is required!';
    } else if (values.lastname.length <= 1) {
      errors.lastname = 'Lastname has to be 1 character at less!';
    }
  
    if (!values.phoneNumber) {
      errors.phoneNumber = 'Number is required!';
    }
    return errors;
   }

  // async function verifyPhoneNumber(phoneNumber) {
  //   var appVerifier = window.recaptchaVerifier;

  //   await AuthInstance.AskOTPCode(phoneNumber, appVerifier, getCodeForLink);
  // }

  // async function getCodeForLink() {
  //   let verificationCode;
  //   const result = await Swal.fire({
  //     title: 'Enter the SMS code that we just sended',
  //     input: 'text',
  //     inputAttributes: {
  //       autocapitalize: 'off'
  //     },
  //     showCancelButton: true,
  //     confirmButtonText: 'Submit',
  //     showLoaderOnConfirm: true,
  //     preConfirm: (code) => {
  //       verificationCode = code;
  //     },
  //     allowOutsideClick: () => !Swal.isLoading()
  //   })
    
  //   if (result.value) {
  //     Swal.fire({
  //       icon: 'success',
  //       title: 'Your phoneNumber has been verified',
  //     })
  //   }

  //   return verificationCode
  // }
  
  async function updateUserData(dataObject) {
    if (user.uid !== 'none') {
      const userInformation = {
        email: user.email,
        accountAproved: true,
        name: `${dataObject.firstname} ${dataObject.lastname}`,
        address: dataObject.address,
        phoneNumber: dataObject.phoneNumber,
      }

      const informationResp = await db.uploadDoc(userInformation, `guardians/`, `${user.uid}`);
      // path must be odd for collections

      if (informationResp.error) {
        Swal.fire({
          title: 'Ups....',
          text: `something happened creating the user ${informationResp.error}`,
          icon: 'error',
        })
      }
      setRedirect('/');
    }else{
      Swal.fire({
        icon: 'error',
        title: "You're not logged in",
      });
    }
  }

  useEffect(() => {
    window.recaptchaVerifier = new AuthInstance.authMethod.RecaptchaVerifier(captchaRef.current || 'captcha', {
      'size': 'invisible',
      'callback': function(response) {
        // reCAPTCHA solved, allow signInWithPhoneNumber.
        console.log('Captcha Solved', response);
        // AuthInstance.onSignInSubmit();
      }
    });

    window.recaptchaVerifier.render().then(function(widgetId) {
      window.recaptchaWidgetId = widgetId;
    })
  }, [AuthInstance.authMethod.RecaptchaVerifier])
  
  return (
    <section className='RegisterUser'>
      <div className='titleSection'>
        <h2>Complete your registration</h2>
      </div>
      <div className='RegisterUser__form'>
        <DynamicForm 
          dataForm={ dataForm }
          onSubmit={ updateUserData }
          messageForButton={ 'Next' }
          validation= { validation }
        />
      </div>
      <div id='captcha' ref={captchaRef}> 
      </div>
      {redirect &&
        <>
          <Redirect
            to={ redirect }
          />
        </>
      }
    </section>
  );
}

export default RegisterUser;