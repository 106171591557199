import React, {useState, useEffect} from 'react';
import { FireStoreService } from '../../services/firebase/firebaseService';
import Swal from 'sweetalert2';
import './Users.css';
import AddDirectory from '../AddDirectory/AddDirectory';
import { renderComponentInSwal } from '../../services/swal/renderMyComponent';

export default () => {
  const [users, setUsers] = useState([]);

  function removeFirebaseDirectory(id){
    Swal.fire({
      title: 'are u sure?',
      text: `Do you really want to delete the directory?`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      cancelButtonText: 'NO, cancel',
      confirmButtonText: 'SI!'
    }).then((result) => {
      if (result.value) {
        if (id) {
          FireStoreService.deleteDoc('directories', id);
          Swal.fire({
            icon: 'success',
            title: 'The directory has been deleted.',
          })
        }else{
          Swal.fire({
            icon: 'error',
            title: 'Error',
            text: `There were an error deleting the directory.`,
          })
        }
      }
    })
  }

  useEffect(() => {
    const addUser = (user) => {
      const userData = user.data();
      setUsers((prev) => ([
        ...prev,
        <div className='users__user' key={user.id}>
          <span>{userData.name}</span>
          <span>{userData.EIN}</span>
          <span className='closeButton' onClick={() => removeFirebaseDirectory(user.id)}></span>
        </div>
      ]));
      return null;
    }
    
    const changeUser = () => {};

    const removeUser = (user) => {
      setUsers(prev => prev.filter((localUser) => user.id !== localUser.key ));
    }

    FireStoreService.getRealTimeCollection('directories', addUser, changeUser, removeUser);
  }, []);

  function createUser() {
    renderComponentInSwal(<AddDirectory />, 'Directory added', 'the directory has been added');
  }

  return (
    <div>
      <div className='titleSection'>
        <h1>Directories</h1>
        <button className='buttonPrimary' onClick={createUser}>Add directory</button>
      </div>
      <div className='users'>
        {users}
      </div>
    </div>
  )
}