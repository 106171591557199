const reducer = (state = {}, action) =>{
  switch (action.type) {
    case 'CHANGE_USER':
      return {
        ...state,
        'user': action.payload
      }

    case 'CHANGE_METRIC_UNIT': {
      return {
        ...state,
        'feets': action.payload
      }
    }

    default:
      return state
  }
}

export default reducer