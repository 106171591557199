import React, { useState } from 'react';
import { FireStoreService } from '../../services/firebase/firebaseService';
import DynamicForm from '../../components/dynamic_form/dynamic_form';
import { useSelector } from 'react-redux';
import './AddChild.css';

// eslint-disable-next-line import/no-anonymous-default-export
export default ({ sweetAlert, initialData, school }) => {
  const [fromOriginData, ] = useState([
    {
      id: 'name',
      type: 'string',
      info: 'Name',
      hint: 'Jhon Doe',
      value: initialData.name,
    },
    {
      id: 'email',
      type: 'string',
      value: initialData.email,
      hint: 'email@example.com',
      info: 'Email',
      disabled: !initialData.LeaderUID
    },
    {
      id: 'address',
      type: 'string',
      value: initialData.address,
      hint: '23 street',
      info: 'Address'
    },
    {
      id: 'phoneNumber',
      type: 'phoneNumber',
      info: 'Mobile Phone',
      // className: 'dynamic_form__input__withButton',
      hint: '+1 555 555 5555',
      value: initialData.phoneNumber,
      // onClickEvent: verifyPhoneNumber,
      // buttonText: 'Verify Phone Number'
    },
  ]);

  async function handleSubmit(data) {
    try {
      const newData = {
        name: data.name,
        phoneNumber: data.phoneNumber,
        email: data.email,
        address: data.address,
        school
      }

      const resp = await FireStoreService.updateDoc(newData, 'guardians', initialData.id);
      if (resp) {
        sweetAlert.clickConfirm();
      }
    } catch (err) {
      throw err;
    }
  }

  function validation(values) {
    let errors = {};
   
    if (!values.name) {
      errors.name = 'name is required!';
    } else if (values.name.length <= 1) {
      errors.name = 'name has to be 1 character at less!';
    }
  
    if (!values.phoneNumber) {
      errors.phoneNumber = 'PhoneNumber is required!';
    }

    return errors;
  }

  return (
    <div className='addChild'>
      <h1>Edit guardian</h1>
      <DynamicForm
        dataForm={fromOriginData}
        onSubmit={handleSubmit}
        messageForButton='Edit guardian'
        validation={validation}
      />
    </div>
  );
};