import React, {useState, useEffect} from 'react';
import DataTable from 'react-data-table-component';
import { FireStoreService } from '../../services/firebase/firebaseService';
 

export const TableForAlgoliaResults = ({columnsResults, ExpandableComponent, hits, collection}) => {
  const [dataForRows, setDataForRows] = useState([]);

  useEffect(() => {
    async function bringDataForRows(collection) {
      try {
        console.log('Start of function', collection);
        const promisesArray = hits.map(result => {
          return FireStoreService.getDocWithid(collection, result.objectID);
        });

        const data = await Promise.all(promisesArray);
        return data;
      } catch (err) {
        throw err;
      }
    }

    if (collection && hits) bringDataForRows(collection, hits).then(results => {
      setDataForRows(() => results);
    }).catch(err => {
      console.log('Something happened bringing dato for algolia results', err);
    })
  }, [hits, collection]);

  console.log('Hits --->', hits);
  return (
    <DataTable
      columns={columnsResults}
      data={dataForRows}
      expandableRows
      expandableRowsComponent={ExpandableComponent}
    />
  )
}